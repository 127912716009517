<template>
  <div>
    <v-row justify="center" align-content="center">
      <v-col cols="12" md="6" lg="4">
        <v-card outlined>
          <v-toolbar color="secondary" dense dark>
            <v-toolbar-title>
              <span>Recupera tu cuenta</span>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <div v-if="loading"></div>
            <div v-else-if="error">
              <v-alert type="error" dense text>{{ error }}</v-alert>
              <v-btn
                color="secondary"
                outlined
                small
                @click="$router.push('/login')"
                >Regresar a Login</v-btn
              >
            </div>
            <div v-else>
              <p class="text-center">
                A continuación, ingresa la nueva contraseña con la que quieres
                volver a iniciar sesión.
              </p>
              <password-form
                :recoverPasswordId="passwordRecoverId"
              ></password-form>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import serverRequestMixin from "../../mixins/serverRequest.mixin";

export default {
  mixins: [serverRequestMixin],

  components: {
    "password-form": () => import("./newPassword.form.vue"),
  },

  data() {
    return {
      loading: false,
      error: "",
    };
  },

  computed: {
    passwordRecoverId() {
      return this.$route.params.passwordRecoverId;
    },
  },

  mounted() {
    this.loadPasswordRecoverInfo();
  },

  methods: {
    async loadPasswordRecoverInfo() {
      this.loading = true;

      try {
        await this.getRequest(`/passwordRecover/${this.passwordRecoverId}`);
      } catch (error) {
        if (error.data && error.status != 500) this.error = error.data.message;
        else {
          console.error(error);
          this.error =
            "Error obteniendo la solicitud de recuperación. Por favor contacta con soporte.";
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
